@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 100;
  src: url(./Raleway/Raleway-italic-100.woff2) format("woff2"),
    url(./Raleway/Raleway-italic-100.woff) format("woff"),
    url(./Raleway/Raleway-italic-100.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 300;
  src: url(./Raleway/Raleway-italic-300.woff2) format("woff2"),
    url(./Raleway/Raleway-italic-300.woff) format("woff"),
    url(./Raleway/Raleway-italic-300.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 400;
  src: url(./Raleway/Raleway-italic-400.woff2) format("woff2"),
    url(./Raleway/Raleway-italic-400.woff) format("woff"),
    url(./Raleway/Raleway-italic-400.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 700;
  src: url(./Raleway/Raleway-italic-700.woff2) format("woff2"),
    url(./Raleway/Raleway-italic-700.woff) format("woff"),
    url(./Raleway/Raleway-italic-700.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 900;
  src: url(./Raleway/Raleway-italic-900.woff2) format("woff2"),
    url(./Raleway/Raleway-italic-900.woff) format("woff"),
    url(./Raleway/Raleway-italic-900.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 100;
  src: url(./Raleway/Raleway-normal-100.woff2) format("woff2"),
    url(./Raleway/Raleway-normal-100.woff) format("woff"),
    url(./Raleway/Raleway-normal-100.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  src: url(./Raleway/Raleway-normal-300.woff2) format("woff2"),
    url(./Raleway/Raleway-normal-300.woff) format("woff"),
    url(./Raleway/Raleway-normal-300.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url(./Raleway/Raleway-normal-400.eot);
  src: local("Raleway"), url(./Raleway/Raleway-normal-400.woff2) format("woff2"),
    url(./Raleway/Raleway-normal-400.eot?#iefix) format("embedded-opentype"),
    url(./Raleway/Raleway-normal-400.woff) format("woff"),
    url(./Raleway/Raleway-normal-400.ttf) format("truetype"),
    url(./Raleway/Raleway-normal-400.svg#Raleway) format("svg");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: url(./Raleway/Raleway-normal-700.woff2) format("woff2"),
    url(./Raleway/Raleway-normal-700.woff) format("woff"),
    url(./Raleway/Raleway-normal-700.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  src: url(./Raleway/Raleway-normal-900.woff2) format("woff2"),
    url(./Raleway/Raleway-normal-900.woff) format("woff"),
    url(./Raleway/Raleway-normal-900.ttf) format("truetype");
}
