@import url(/src/presentation/assets/fonts/Poppins.css);
@import url(/src/presentation/assets/fonts/Raleway.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

//--------------- INNER HTML FIXES --------------------//

.inner-color-grey {
  color: #606576;
}

.inner-color-error {
  color: #d9213f;
}

//---------------- MATERIAL --------------------//

// Change Dialog
.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: var(--extra-white-08, rgba(255, 255, 255, 0.8)) !important;
  backdrop-filter: blur(5px);
  padding: 24px;
}

//---------------- NUMBER INPUTS -------------//
// Input number arrow buttons
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  opacity: 1;
  margin-left: 36px;
}

// ------------------ GUARDIAN LIST SCROLLBAR (NEW CONFIG - STEP3) ---------------//

.guardian-list::-webkit-scrollbar,
.service-list::-webkit-scrollbar {
  width: 5px;
  background: #d9d9d9;
}

.guardian-list::-webkit-scrollbar-thumb,
.service-list::-webkit-scrollbar-thumb {
  background: #282a36;
  border-radius: 10px;
}

//---------------- TAILWIND --------------------//

@layer base {
  /* Text */

  body {
    @apply font-['Raleway'] text-base font-normal bg-background-color;
  }

  h1 {
    @apply font-['Poppins'] text-[32px] leading-[48px] font-light;
  }

  h2 {
    @apply font-['Poppins'] text-[26px] leading-[42px] font-extrabold;
  }

  h3 {
    @apply font-['Poppins'] text-[22px] font-normal;
  }

  h4 {
    @apply font-['Poppins'] text-[18px] font-semibold leading-8;
  }

  .text-overline {
    @apply font-['Poppins'] text-xs leading-[19.5px] font-normal tracking-[0.18px];
  }

  .text-small {
    @apply font-['Raleway'] text-[14px] leading-normal;
  }

  .text-mini {
    @apply font-['Raleway'] text-xs font-normal tracking-[0.18px];
  }
}

@layer components {
  /* Main Containers */

  // Containers for pages without any navbar, mainly access and not logged
  .main-container {
    @apply py-6 px-4 sm:px-6;
  }

  // Container for pages with navbar through keyowner and guardian containers
  .main-nav-container {
    min-height: calc(100vh - 133px);
    @apply sm:p-6 sm:bg-extra-white sm:rounded-ten sm:drop-shadow-[0_2px_4px_rgba(16,20,31,0.10)];
  }

  // Container for public pages with just text
  .main-text-container {
    min-height: calc(100vh - 69px - 16px);
    @apply sm:p-6 sm:bg-extra-white sm:rounded-ten sm:drop-shadow-[0_2px_4px_rgba(16,20,31,0.10)];
  }

  /* Buttons */

  .custom-button {
    @apply h-12 py-3 px-6 text-base font-bold rounded-[32px] w-full grid place-content-center whitespace-nowrap;
  }

  .btn-primary {
    @apply custom-button bg-primary text-extra-white hover:bg-primary-hover active:bg-primary active:outline active:outline-4 active:outline-primary-light focus-visible:outline focus-visible:outline-4 focus-visible:outline-primary-light disabled:opacity-50 disabled:pointer-events-none;
  }

  .btn-secondary {
    @apply custom-button bg-transparent text-primary border-2 border-primary hover:text-primary-hover hover:bg-color1-03 hover:border-primary-hover active:text-primary active:bg-color1-03 active:border-primary active:outline active:outline-4 active:outline-primary-light focus-visible:outline focus-visible:outline-4 focus-visible:outline-primary-light disabled:opacity-50 disabled:pointer-events-none;
  }

  .btn-flat {
    @apply custom-button bg-transparent text-secondary hover:text-secondary-hover active:text-secondary active:bg-color3-0.3 focus-visible:outline-none focus-visible:bg-color3-0.3 disabled:opacity-30 disabled:pointer-events-none;
  }

  .btn-tertiary {
    @apply custom-button bg-color4-04 text-tertiary hover:bg-color3-0.3 active:bg-color4-04 active:outline active:outline-4 active:outline-color4 focus-visible:outline focus-visible:outline-4 focus-visible:outline-color4 disabled:opacity-30 disabled:pointer-events-none;
  }

  /* Form Inputs */

  .custom-input {
    label {
      @apply text-small;
      span {
        @apply text-red-500 ml-2;
      }
    }
    .custom-field {
      @apply flex mt-2;

      // Input
      input {
        @apply w-full py-3 pl-4 pr-12 focus-visible:outline-primary  bg-white rounded-ten placeholder:text-grey text-black;
      }

      .input-error {
        @apply outline outline-1 outline-warning;
      }

      .password {
        @apply pr-20;
      }

      // Icon
      .icon-suffix {
        @apply relative w-0 pt-3.5 cursor-pointer;

        div {
          @apply block;
        }
      }

      // Specific icons rules
      .reset-text {
        @apply left-[-40px] text-grey;
      }
      .reset-pass {
        @apply left-[-72px] text-grey;
      }
      .visibility {
        @apply left-[-40px] text-primary;
      }
    }

    .custom-error {
      @apply flex gap-2 mt-1.5 items-center text-error text-overline;
      .icon-warning {
        @apply flex;
      }
    }
    .custom-helper {
      @apply mt-1.5 text-mini text-grey;
    }

    // Hours-minutes input

    .time-field {
      @apply flex flex-col;

      .number-box {
        @apply flex w-40;

        input {
          @apply w-40 px-4 py-3 bg-white rounded-[10px] focus-visible:outline-primary text-grey;
        }

        .reset-icon-suffix {
          @apply relative mr-[-20px] cursor-pointer left-[-60px] text-grey;
        }
      }
      p {
        @apply text-mini text-grey mt-1.5;
      }
    }
  }

  // Number-input

  .number-input {
    @apply flex w-40;

    input {
      @apply w-40 px-4 py-3 bg-white rounded-[10px] focus-visible:outline-primary text-grey;
    }

    .reset-icon-suffix {
      @apply relative mr-[-20px] cursor-pointer left-[-60px] text-grey;
    }
  }

  // Number-input

  .number-input {
    @apply flex w-40;

    input {
      @apply w-40 px-4 py-3 bg-white rounded-[10px] focus-visible:outline-primary text-grey;
    }

    .reset-icon-suffix {
      @apply relative mr-[-20px] cursor-pointer left-[-60px] text-grey;
    }
  }

  /* Error banner */

  .error-banner {
    @apply flex items-center gap-2 p-2 mb-4 text-error bg-error04 rounded-four;

    p {
      @apply text-overline;
    }
  }

  /* Steps Wizard - Badges */
  .wizard-badge {
    @apply grid grid-cols-[repeat(2,min-content)] gap-2 whitespace-nowrap place-items-center opacity-50;

    .badge {
      @apply grid place-items-center w-6 h-6 rounded-full border-[1px] border-secondary-light text-center;

      h4 {
        @apply text-secondary-light text-overline;
      }
    }

    p {
      @apply text-small uppercase text-[#61646B];
    }
  }

  .wizard-badge.active {
    @apply grid grid-cols-[repeat(2,min-content)] gap-2 whitespace-nowrap place-items-center opacity-100;

    .badge {
      @apply w-8 h-8 bg-secondary border-none;

      h4 {
        @apply text-white text-lg font-semibold;
      }
    }

    p {
      @apply text-black text-base;
    }

    .next {
      @apply text-grey text-mini normal-case;
    }
  }

  .wizard-badge:has(~ .active) {
    @apply opacity-100;

    .badge {
      @apply bg-secondary opacity-50 border-none;

      h4 {
        @apply text-white;
      }
    }
  }

  /* Previous/next button */

  .prev-next-btn {
    @apply flex gap-1.5 place-items-center disabled:opacity-50;

    custom-icon {
      @apply text-secondary;
    }
    p {
      @apply text-small;
    }
  }

  /* Imagen de Empty */
  .empty-img {
    @apply w-full max-w-md m-auto;
  }
}
