@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: url(./Poppins/Poppins-normal-100.ttf) format("truetype"),
    url(./Poppins/Poppins-normal-100.woff) format("woff"),
    url(./Poppins/Poppins-normal-100.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url(./Poppins/Poppins-normal-300.ttf) format("truetype"),
    url(./Poppins/Poppins-normal-300.woff) format("woff"),
    url(./Poppins/Poppins-normal-300.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(./Poppins/Poppins.eot);
  src: local("Poppins"),
    url(./Poppins/Poppins.eot?#iefix) format("embedded-opentype"),
    url(./Poppins/Poppins.ttf) format("truetype"),
    url(./Poppins/Poppins.woff) format("woff"),
    url(./Poppins/Poppins.woff2) format("woff2"),
    url(./Poppins/Poppins.svg#Poppins) format("svg");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url(./Poppins/Poppins-normal-700.ttf) format("truetype"),
    url(./Poppins/Poppins-normal-700.woff) format("woff"),
    url(./Poppins/Poppins-normal-700.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: url(./Poppins/Poppins-normal-900.ttf) format("truetype"),
    url(./Poppins/Poppins-normal-900.woff) format("woff"),
    url(./Poppins/Poppins-normal-900.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  src: url(./Poppins/Poppins-italic-100.ttf) format("truetype"),
    url(./Poppins/Poppins-italic-100.woff) format("woff"),
    url(./Poppins/Poppins-italic-100.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src: url(./Poppins/Poppins-italic-300.ttf) format("truetype"),
    url(./Poppins/Poppins-italic-300.woff) format("woff"),
    url(./Poppins/Poppins-italic-300.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url(./Poppins/Poppins-italic-400.ttf) format("truetype"),
    url(./Poppins/Poppins-italic-400.woff) format("woff"),
    url(./Poppins/Poppins-italic-400.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: url(./Poppins/Poppins-italic-700.ttf) format("truetype"),
    url(./Poppins/Poppins-italic-700.woff) format("woff"),
    url(./Poppins/Poppins-italic-700.woff2) format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  src: url(./Poppins/Poppins-italic-900.ttf) format("truetype"),
    url(./Poppins/Poppins-italic-900.woff) format("woff"),
    url(./Poppins/Poppins-italic-900.woff2) format("woff2");
}
